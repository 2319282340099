/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
  font-family: 'Noto Sans';
}

body {
  margin: 0;
}

form {
  width: 100%;
}

table {
  width: 100%;
  margin-top: 14px;
  margin-bottom: 14px;
}

mat-form-field {
  width: 100%;
}

td.mat-cell:first-of-type,
th.mat-header-cell:first-of-type {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

td.mat-cell:not(:first-of-type),
th.mat-header-cell:not(:first-of-type) {
  padding: 10px !important;
}

td.mat-cell,
th.mat-header-cell{
  font-size: small;
}

.actions-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td.actions-column,
th.actions-column {
  text-align: center !important;
}

/*td.mat-cell:not(:first-of-type).actions-column {*/
/*  padding: 10px 0 !important;*/
/*}*/

.page-center-container {
  padding-top: 2rem;
  padding-left: 15vw;
  padding-right: 15vw;
}

.mat-chip {
  font-size: small;
}

.data-row {
  padding-top: 10px;
  padding-bottom: 10px;
}

.error-message {
  color: red;
}

.mat-checkbox-layout{
  white-space: normal !important;

}
.mat-checkbox-inner-container{
  margin-top: 4px;
}

.button-row {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.error-snackbar {
  color: white !important;
  background: darkred !important;
}
